import "@fullcalendar/core/main.css";
import "@fullcalendar/daygrid/main.css";
import "@fullcalendar/timegrid/main.css";
import classNames from "classnames";
import jwt from "jsonwebtoken";
import "primeflex/primeflex.css";
import "primereact/resources/primereact.min.css";
import React, { Component } from "react";
import { usePromiseTracker } from "react-promise-tracker";
import { withRouter } from "react-router";
import { Redirect, Route } from "react-router-dom";
import "./App.css";
import { AppBreadcrumb } from "./AppBreadcrumb";
import { AppConfig } from "./AppConfig";
import { AppFooter } from "./AppFooter";
import { AppInlineProfile } from "./AppInlineProfile";
import { AppMenu } from "./AppMenu";
import { AppRightPanel } from "./AppRightPanel";
import { AppTopbar } from "./AppTopbar";
import { Dashboard } from "./components/Dashboard";
import CompanyAdd from "./components/Master/CompanyAdd";
import CompanyEdit from "./components/Master/CompanyEdit";
import { CompanyList } from "./components/Master/CompanyList";
import CustomerAdd from "./components/Master/CustomerAdd";
import CustomerEdit from "./components/Master/CustomerEdit";
import { CustomerList } from "./components/Master/CustomerList";
import DragNDrop from "./components/Master/DragNDrop";
import TemplateAdd from "./components/Master/TemplateAdd";
import { TemplateHistoryList } from "./components/Master/TemplateHistoryList";
import { TemplateRequestList } from "./components/Master/TemplateRequestList";
import UserAdd from "./components/User management/UserAdd";
import UserEdit from "./components/User management/UserEdit";
import { UserList } from "./components/User management/UserList";
import UserProfile from "./components/UserProfile";
import Settings from "./components/Settings/Settings";

import GenerateRandomCode from "./components/Master/GenerateRandomCode";
import { RendomCodeGenerationList } from "./components/Master/RendomCodeGenerationList";
import PrintingJobAdd from "./components/Printing/PrintingJobAdd";
import { PrintingJobList } from "./components/Printing/PrintingJobList";
import "./ripple.js";
import "./style/loader.css";
import "./style/mystyle.css";
import SelectOption from "./components/Master/SelectOption";
import TemplateAdd1 from "./components/Master/TemplateAdd1";
import GenerateToken from "./components/Master/GenerateToken";
import { RollAdd } from "./components/Printing/RollAdd";
import { RollList } from "./components/Printing/RollList";
import { RollEdit } from "./components/Printing/RollEdit";
import PlantAdd from "./components/Master/PlantAdd";
import { PlantList } from "./components/Master/PlantList";
import PlantEdit from "./components/Master/PlantEdit";
import { PackagingList } from "./components/Master/Packaging/PackagingList";
import PackagingAdd from "./components/Master/Packaging/PackagingAdd";
import PackagingEdit from "./components/Master/Packaging/PackagingEdit";
import VendorAdd from "./components/Master/VendorAdd";
import { VendorList } from "./components/Master/VendorList";
import VendorEdit from "./components/Master/VendorEdit";
import ProductAdd from "./components/Master/ProductAdd";
import { ProductList } from "./components/Master/ProductList";
import ProductEdit from "./components/Master/ProductEdit";
import { RollFileList } from "./components/Printing/RollFileList";
import UserPriviladge from "./components/User management/UserPriviladge";
import View from "./components/Master/View";
import { PriviladgeContext } from "./context/priviladge-context";
import { HttpService } from "./service/HttpService";
import ApUtilityAdd from "./components/ApUtility/ApUtilityAdd";
import { ApUtilityList } from "./components/ApUtility/ApUtilityList";
import { MatnrList } from "./components/ApUtility/MatntList";
import MatntAdd from "./components/ApUtility/MatntAdd";
import MatnrEdit from "./components/ApUtility/MatnrEdit";
import { InvalidScanList } from "./components/Master/InvalidScanList";
import GuillocheImageAdd from "./components/Master/GuillocheImageAdd";
import { GuillocheImageList } from "./components/Master/GuillocheImageList";
import GuillocheImageEdit from "./components/Master/GuillocheImageEdit";
import { Report } from "./components/Master/Report";
import Reports from "./components/Master/Reports";
import { InventoryList } from "./components/InventoryList";
import { ConsignmentList } from "./components/ConsignmentList";
import { ConsignmentCodeList } from "./constant/ConsignmentCodeList";
import TokenDataReport from "./components/ApUtility/TokenDataReport";
import InventoryReport from "./components/InventoryReport";
import LinkQrWithProduct from "./components/Master/LinkQrWithProduct";
import EndUserRewardReport from "./components/Master/EndUserRewardReport"
import SearchQrCodeList from "./components/SearchQrCodeList";
import SearchQrCode from "./components/SearchQrCode";
import InvalidScanReport from "./components/Master/InvalidScanReport";
import { SmartBinList } from "./components/Smart-bin/SmartBinList";
import AnalyticalDashboard from "./components/AnalyticalDashboard";

var _ = require("lodash");

const LoadingIndicator = (props) => {
  const { promiseInProgress } = usePromiseTracker();

  return promiseInProgress && <div class="loading">Loading&#8230;</div>;
};
const ProtectedRoute = ({ component: Comp, path, name, ...rest }) => {
  const token = localStorage.getItem("token");

  var loggedIn = isTokenValid(token);
  return (
    <Route
      path={path}
      {...rest}
      render={(props) => {
        return loggedIn ? (
          <Comp {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: {
                prevLocation: path,
                error: "You need to login first!",
              },
            }}
          />
        );
      }}
    />
  );
};

class App extends Component {
  constructor() {
    super();
    this.state = {
      layoutMode: "static",
      profileMode: "top",
      overlayMenuActive: false,
      staticMenuDesktopInactive: false,
      staticMenuMobileActive: false,
      rotateMenuButton: false,
      topbarMenuActive: false,
      activeTopbarItem: null,
      darkMenu: false,
      rightPanelActive: false,
      menuActive: false,
      themeColor: "blue",
      configDialogActive: false,
      role: "ROLE_ADMIN",
      company: {},
      userPriviladge: [{ name: "chaman" }],
      updateUserPriviladge: this.updateUserPriviladge,
    };
    this.httpService = new HttpService();
    var obj = {};
    if (
      localStorage.getItem("user") !== undefined &&
      localStorage.getItem("user") !== null
    ) {
      obj = JSON.parse(localStorage.getItem("user"));
      this.state.company = obj.company;
      this.state.role = obj.authorities[0];
      // this.setState({company:obj.company})
      // this.setState({role:obj.authorities[0]})
    }

    this.onDocumentClick = this.onDocumentClick.bind(this);
    this.onMenuClick = this.onMenuClick.bind(this);
    this.onMenuButtonClick = this.onMenuButtonClick.bind(this);
    this.onTopbarMenuButtonClick = this.onTopbarMenuButtonClick.bind(this);
    this.onTopbarItemClick = this.onTopbarItemClick.bind(this);
    this.onMenuItemClick = this.onMenuItemClick.bind(this);
    this.onRootMenuItemClick = this.onRootMenuItemClick.bind(this);
    this.onRightPanelButtonClick = this.onRightPanelButtonClick.bind(this);
    this.onRightPanelClick = this.onRightPanelClick.bind(this);
    this.changeMenuMode = this.changeMenuMode.bind(this);
    this.changeMenuColor = this.changeMenuColor.bind(this);
    this.changeProfileMode = this.changeProfileMode.bind(this);
    this.changeTheme = this.changeTheme.bind(this);
    this.onConfigButtonClick = this.onConfigButtonClick.bind(this);
    this.onConfigCloseClick = this.onConfigCloseClick.bind(this);
    this.onConfigClick = this.onConfigClick.bind(this);
  }
  updateUserPriviladge = (userPriviladge) => {
    this.setState(
      { userPriviladge: userPriviladge },
      this.createMenu(userPriviladge)
    );
  };
  userPriviladge = () => {
    this.httpService
      .getApi("user-priviladges")
      .then((response) => {
        if (this.state.role == "ROLE_USER") {
          response.data.map((x) => {
            if (x.name == "Track & Trace" && x.maxPriority === x.priority)
              localStorage.setItem("track&Trace", true);
          });
        }
        this.updateUserPriviladge(response.data);
      })
      .catch((error) => { });
  };
  componentDidMount() {
    this.changeTheme({ theme: "blue" });
    this.userPriviladge();
  }
  onMenuClick(event) {
    this.menuClick = true;
  }

  onMenuButtonClick(event) {
    this.menuClick = true;
    this.setState({
      rotateMenuButton: !this.state.rotateMenuButton,
      topbarMenuActive: false,
    });

    if (this.state.layoutMode === "overlay") {
      this.setState({
        overlayMenuActive: !this.state.overlayMenuActive,
      });
    } else {
      if (this.isDesktop())
        this.setState({
          staticMenuDesktopInactive: !this.state.staticMenuDesktopInactive,
        });
      else
        this.setState({
          staticMenuMobileActive: !this.state.staticMenuMobileActive,
        });
    }

    event.preventDefault();
  }

  onTopbarMenuButtonClick(event) {
    this.topbarItemClick = true;
    this.setState({ topbarMenuActive: !this.state.topbarMenuActive });
    this.hideOverlayMenu();
    event.preventDefault();
  }

  onTopbarItemClick(event) {
    this.topbarItemClick = true;

    if (this.state.activeTopbarItem === event.item)
      this.setState({ activeTopbarItem: null });
    else this.setState({ activeTopbarItem: event.item });

    event.originalEvent.preventDefault();
  }

  onMenuItemClick(event) {
    if (!event.item.items) {
      this.hideOverlayMenu();
    }

    if (!event.item.items && (this.isHorizontal() || this.isSlim())) {
      this.setState({
        menuActive: false,
      });
    }
  }

  onRootMenuItemClick(event) {
    this.setState({
      menuActive: !this.state.menuActive,
    });
  }

  onRightPanelButtonClick(event) {
    this.rightPanelClick = true;
    this.setState({
      rightPanelActive: !this.state.rightPanelActive,
    });
    event.preventDefault();
  }

  onRightPanelClick(event) {
    this.rightPanelClick = true;
  }

  onDocumentClick(event) {
    if (!this.topbarItemClick) {
      this.setState({
        activeTopbarItem: null,
        topbarMenuActive: false,
      });
    }

    if (!this.menuClick) {
      if (this.isHorizontal() || this.isSlim()) {
        this.setState({
          menuActive: false,
        });
      }

      this.hideOverlayMenu();
    }

    if (!this.configClick) {
      this.setState({ configDialogActive: false });
    }

    if (!this.rightPanelClick) {
      this.setState({
        rightPanelActive: false,
      });
    }

    this.topbarItemClick = false;
    this.menuClick = false;
    this.configClick = false;
    this.rightPanelClick = false;
  }

  hideOverlayMenu() {
    this.setState({
      rotateMenuButton: false,
      overlayMenuActive: false,
      staticMenuMobileActive: false,
    });
  }

  isTablet() {
    let width = window.innerWidth;
    return width <= 1024 && width > 640;
  }

  isDesktop() {
    return window.innerWidth > 1024;
  }

  isMobile() {
    return window.innerWidth <= 640;
  }

  isOverlay() {
    return this.state.layoutMode === "overlay";
  }

  isHorizontal() {
    return this.state.layoutMode === "horizontal";
  }

  isSlim() {
    return this.state.layoutMode === "slim";
  }

  onConfigButtonClick(event) {
    this.configClick = true;
    this.setState({ configDialogActive: !this.state.configDialogActive });
  }

  onConfigCloseClick() {
    this.setState({ configDialogActive: false });
  }

  onConfigClick() {
    this.configClick = true;
  }

  changeMenuMode(event) {
    this.setState({ layoutMode: event.menuMode });
    if (event.menuMode === "horizontal") {
      this.setState({ profileMode: "top" });
    }
  }

  changeMenuColor(event) {
    this.setState({ darkMenu: event.darkMenu });
  }

  changeProfileMode(event) {
    this.setState({ profileMode: event.profileMode });
  }

  changeTheme(event) {
    this.setState({ themeColor: event.theme });
    this.changeStyleSheetUrl("layout-css", event.theme, "layout");
    this.changeStyleSheetUrl("theme-css", event.theme, "theme");
  }

  changeStyleSheetUrl(id, value, prefix) {
    let element = document.getElementById(id);
    let urlTokens = element.getAttribute("href").split("/");
    urlTokens[urlTokens.length - 1] = prefix + "-" + value + ".css";
    let newURL = urlTokens.join("/");

    this.replaceLink(element, newURL);
  }

  isIE() {
    return /(MSIE|Trident\/|Edge\/)/i.test(window.navigator.userAgent);
  }

  replaceLink(linkElement, href) {
    if (this.isIE()) {
      linkElement.setAttribute("href", href);
    } else {
      const id = linkElement.getAttribute("id");
      const cloneLinkElement = linkElement.cloneNode(true);

      cloneLinkElement.setAttribute("href", href);
      cloneLinkElement.setAttribute("id", id + "-clone");

      linkElement.parentNode.insertBefore(
        cloneLinkElement,
        linkElement.nextSibling
      );

      cloneLinkElement.addEventListener("load", () => {
        linkElement.remove();
        cloneLinkElement.setAttribute("id", id);
      });
    }
  }

  createMenu(userPriviladge) {
    let adminManu = [];
    let userMenu = [];
    let trackNTraceMenu = [];
    let superAdminMenu = [];
    let commonMenu = [];
    let smartBinMenu = [];
    if (this.state.role === "ROLE_SUPER_ADMIN") {
      superAdminMenu = [
        {
          label: "Company Master",
          icon: "storage",
          badgeStyleClass: "teal-badge",
          name: "Master",
          priority: 1,
          items: [
            {
              label: "Company",
              icon: "local_mall",
              to: "/company_list",
              name: "Company",
              priority: 1,
            },
          ],
        },
        {
          label: "Template Request",
          icon: "insert_drive_file",
          badgeStyleClass: "teal-badge",
          name: "Template Request",
          priority: 1,
          items: [
            {
              label: "Template Request",
              icon: "insert_drive_file",
              to: "/template_request_list",
              name: "Template Request",
              priority: 1,
            },
          ],
        },
      ];
    }
    if (
      this.state.role === "ROLE_ADMIN" ||
      this.state.role === "ROLE_SUPER_ADMIN" ||
      (localStorage.getItem("track&Trace") != undefined && localStorage.getItem("track&Trace") == "true" && this.state.role === "ROLE_USER")
    ) {
      adminManu = [
        {
          label: "User Management",
          icon: "person",
          badgeStyleClass: "teal-badge",
          name: "User Management",
          priority: 1,
          items: [
            {
              label: "User",
              icon: "people",
              to: "/user_list",
              name: "User",
              priority: 1,
            },
          ],
        },
      ];
    }

    if (
      this.state.role === "ROLE_USER" ||
      this.state.role === "ROLE_ADMIN" ||
      this.state.role == "ROLE_PRODUCER"
    ) {
      userMenu = [
        {
          label: "Master",
          icon: "storage",
          badgeStyleClass: "teal-badge",
          name: "Master",
          priority: 1,
          items: [
            {
              label: "Customer",
              icon: "people",
              to: "/customer_list",
              name: "Customer",
              priority: 1,
            },
            {
              label: "Product",
              icon: "store",
              to: "/product_list",
              name: "Product",
              priority: 1,
            },
            {
              label: "Plant",
              icon: "location_city",
              to: "/plant_list",
              name: "Plant",
              priority: 1,
            },
            {
              label: "Vendor",
              icon: "person",
              to: "/vendor_list",
              name: "Vendor",
              priority: 1,
            },
            {
              label: "Packaging",
              icon: "table_charts",
              to: "/packaging_list",
              name: "Packaging",
              priority: 1,
            },
            {
              label: "Link QR",
              icon: "store",
              to: "/link_qr",
              name: "Link QR",
              priority: 1,
            },
            // { label: 'Guilloche Image', icon: 'person', to: '/guilloche_images_list', name: "Guilloche Image", priority: 1 },
          ],
        },
        {
          label: "Printing",
          icon: "print",
          badgeStyleClass: "teal-badge",
          name: "Printing",
          priority: 1,
          items: [
            {
              label: "Print Data",
              icon: "list",
              to: "/print_data_list",
              name: "Print Data",
              priority: 1,
            },
            {
              label: "Label Production",
              icon: "list",
              to: "/label_production_list",
              name: "Label Production",
              priority: 1,
            },
            {
              label: "Rolls",
              icon: "layers",
              to: "/roll_file_list",
              name: "Rolls",
              priority: 1,
            },
          ],
        },
        {
          label: "Template",
          icon: "insert_drive_file",
          badgeStyleClass: "teal-badge",
          name: "Template",
          priority: 1,
          items: [
            {
              label: "Add Template",
              icon: "drag_handle",
              to: "/template_design",
              name: "Template",
              priority: 2,
            },
            {
              label: "Template",
              icon: "insert_drive_file",
              to: "/template_histories",
              name: "Template",
              priority: 1,
            },
            {
              label: "Download Print Data",
              icon: "picture_as_pdf",
              to: "/template_history_list",
              name: "Token",
              priority: 1,
            },
            {
              label: "Invalid Scan",
              icon: "list",
              to: "/invalid_scan_list",
              name: "Invalid Scan",
              priority: 1,
            },
            {
              label: "Report",
              icon: "list",
              to: "/report",
              name: "Report",
              priority: 1,
            },
            {
              label: "F-Codes Report",
              icon: "list",
              to: "/reports",
              name: "Reports",
              priority: 1,
            },
            {
              label: "EndUser Reward Report",
              icon: "list",
              to: "/enduser_reward_reports",
              name: "EndUser Reward Report",
              priority: 1,
            },
          ],
        },
        {
          label: "Token Data",
          icon: "insert_drive_file",
          badgeStyleClass: "teal-badge",
          name: "Token Data",
          priority: 1,
          items: [
            {
              label: "Master Product",
              icon: "drag_handle",
              to: "/master_product_list",
              name: "Master Product",
              priority: 1,
            },
            {
              label: "Token Data",
              icon: "drag_handle",
              to: "/token_data_list",
              name: "Token Data",
              priority: 1,
            },
            {
              label: "Token Data Report",
              icon: "drag_handle",
              to: "/token_data_report",
              name: "Token Data Report",
              priority: 1,
            },
          ],
        },
      ];
    }

    if (this.state.role == "ROLE_USER" || this.state.role == "ROLE_PRODUCER") {
      trackNTraceMenu = [
        {
          label: "Track & Trace",
          icon: "storage",
          badgeStyleClass: "teal-badge",
          name: "Track & Trace",
          priority: 1,
          items: [
            {
              label: "Business Partner",
              icon: "people",
              to: "/business_partner_list",
              name: "Business Partner",
              priority: 1,
            },
            {
              label: "Inventory",
              icon: "people",
              to: "/inventory_list",
              name: "Inventory",
              priority: 1,
            },
            {
              label: "Consignment",
              icon: "people",
              to: "/consignment_list",
              name: "Consignment",
              priority: 1,
            },
            {
              label: "Parent F-Codes",
              icon: "people",
              to: "/consignment_fcode_list",
              name: "ConsignmentCode",
              priority: 1,
            },
            {
              label: "Inventory Reports",
              icon: "people",
              to: "/inventory_report",
              name: "Inventory Reports",
              priority: 1,
            },
          ],
        },
      ];
    } else {
      trackNTraceMenu = [
        {
          label: "Track & Trace",
          icon: "storage",
          badgeStyleClass: "teal-badge",
          name: "Track & Trace",
          priority: 1,
          items: [
            // { label: 'Business Partner', icon: 'people', to: '/business_partner_list', name: "Business Partner", priority: 1 },
            {
              label: "Inventory",
              icon: "people",
              to: "/inventory_list",
              name: "Inventory",
              priority: 1,
            },
            {
              label: "Consignment",
              icon: "people",
              to: "/consignment_list",
              name: "Consignment",
              priority: 1,
            },
            {
              label: "Parent F-Codes",
              icon: "people",
              to: "/consignment_fcode_list",
              name: "ConsignmentCode",
              priority: 1,
            },
            {
              label: "Inventory Reports",
              icon: "people",
              to: "/inventory_report",
              name: "Inventory Reports",
              priority: 1,
            },
          ],
        },
        {
          label: "Search",
          icon: "search",
          badgeStyleClass: "teal-badge",
          name: "Search",
          priority: 1,
          items: [
            {
              label: "Search QrCode",
              icon: "search",
              to: "/search_qr_code",
              name: "Search QrCode",
              priority: 1,
            },
          ],
        }
      ];
    }

    if (this.state.role == "ROLE_ADMIN" || this.state.role == "ROLE_USER") {
      console.log(this.state.role)
      smartBinMenu = [
        {
          label: "Smart Bin",
          icon: "code",
          badgeStyleClass: "teal-badge",
          name: "Smart Bin",
          priority: 1,
          items: [
            {
              label: "Smart Bin",
              icon: "qrcode",
              to: "/smart-bin",
              name: "Smart Bin",
              priority: 1,
            },
          ]
        }
      ]
    }

    if (this.state.role === "ROLE_USER") {
      this.menu = this.filterByPriviladge(
        [...commonMenu, ...userMenu, ...trackNTraceMenu, ...smartBinMenu],
        userPriviladge
      );
      this.menu.push(...adminManu);
    } else {
      if (this.state.role === "ROLE_PRODUCER") {
        this.menu = this.filterByPriviladge(
          [...commonMenu, ...userMenu, ...trackNTraceMenu, ...smartBinMenu],
          userPriviladge
        );
      } else
        this.menu = [
          ...commonMenu,
          ...adminManu,
          ...userMenu,
          ...superAdminMenu,
          ...trackNTraceMenu,
          ...smartBinMenu
        ];
    }
  }
  filterByPriviladge = (manus, userPriviladge) => {
    let newManu = [];
    for (let index = 0; index < manus.length; index++) {
      const element = manus[index];
      if (this.checkForPriviladge(element, userPriviladge)) {
        let newItem = [];
        for (const item of element.items) {
          if (this.checkForPriviladge(item, userPriviladge)) {
            newItem.push(item);
          }
        }

        if (element.name == "Track & Trace") newManu.push(element);

        if (newItem.length > 0) {
          element.items = newItem;

          newManu.push(element);
        }
      }
    }
    return newManu;
  };
  checkForPriviladge = (item, userPriviladge) => {
    let bol = _.find(userPriviladge, function (n) {
      return n.name === item.name && n.priority >= item.priority;
    });

    return bol;
  };

  render() {
    const layoutContainerClassName = classNames("layout-container", {
      "menu-layout-static": this.state.layoutMode !== "overlay",
      "menu-layout-overlay": this.state.layoutMode === "overlay",
      "layout-menu-overlay-active": this.state.overlayMenuActive,
      "menu-layout-slim": this.state.layoutMode === "slim",
      "menu-layout-horizontal": this.state.layoutMode === "horizontal",
      "layout-menu-static-inactive": this.state.staticMenuDesktopInactive,
      "layout-menu-static-active": this.state.staticMenuMobileActive,
    });
    const menuClassName = classNames("layout-menu", {
      "layout-menu-dark": this.state.darkMenu,
    });
    const AppBreadCrumbWithRouter = withRouter(AppBreadcrumb);

    return (
      <PriviladgeContext.Provider
        value={{
          userPriviladge: this.state.userPriviladge,
          isAdmin: this.state.role === "ROLE_ADMIN",
          isSuperAdmin: this.state.role === "ROLE_SUPER_ADMIN",
          isUser: this.state.role === "ROLE_USER",
          company: this.state.company,
          updateUserPriviladge: this.state.updateUserPriviladge,
        }}
      >
        <div className="layout-wrapper" onClick={this.onDocumentClick}>
          <div
            ref={(el) => (this.layoutContainer = el)}
            className={layoutContainerClassName}
          >
            <AppTopbar
              profileMode={this.state.profileMode}
              horizontal={this.isHorizontal()}
              topbarMenuActive={this.state.topbarMenuActive}
              activeTopbarItem={this.state.activeTopbarItem}
              onMenuButtonClick={this.onMenuButtonClick}
              onTopbarMenuButtonClick={this.onTopbarMenuButtonClick}
              onTopbarItemClick={this.onTopbarItemClick}
              onRightPanelButtonClick={this.onRightPanelButtonClick}
            />

            <div className={menuClassName} onClick={this.onMenuClick}>
              <div className="menu-scroll-content">
                {this.state.profileMode === "inline" &&
                  this.state.layoutMode !== "horizontal" && (
                    <AppInlineProfile />
                  )}
                <AppMenu
                  model={this.menu}
                  onMenuItemClick={this.onMenuItemClick}
                  onRootMenuItemClick={this.onRootMenuItemClick}
                  layoutMode={this.state.layoutMode}
                  active={this.state.menuActive}
                />
              </div>
            </div>

            <div className="layout-main">
              {/* <AppBreadCrumbWithRouter /> */}

              <div className="layout-content">
                <ProtectedRoute
                  path="/dashboard"
                  name="Das"
                  component={this.state.role === "ROLE_USER" ? AnalyticalDashboard : Dashboard}
                />

                <ProtectedRoute path="/user_profile" component={UserProfile} />
                <ProtectedRoute path="/settings" component={Settings} />
                <ProtectedRoute path="/user_list" component={UserList} />
                <ProtectedRoute path="/user_edit" component={UserEdit} />
                <ProtectedRoute path="/user_add" component={UserAdd} />
                <ProtectedRoute
                  path="/company_list"
                  name="company"
                  component={CompanyList}
                />
                <ProtectedRoute
                  path="/company_add"
                  name="company"
                  component={CompanyAdd}
                />
                <ProtectedRoute
                  path="/company_edit"
                  name="company"
                  component={CompanyEdit}
                />
                <ProtectedRoute
                  path="/customer_add"
                  name="customer"
                  component={CustomerAdd}
                />
                <ProtectedRoute
                  path="/customer_edit"
                  name="customer"
                  component={CustomerEdit}
                />
                <ProtectedRoute
                  path="/customer_list"
                  name="customer"
                  component={CustomerList}
                />
                <ProtectedRoute
                  path="/business_partner_add"
                  name="customer"
                  component={CustomerAdd}
                />
                <ProtectedRoute
                  path="/business_partner_edit"
                  name="customer"
                  component={CustomerEdit}
                />
                <ProtectedRoute
                  path="/business_partner_list"
                  name="customer"
                  component={CustomerList}
                />
                <ProtectedRoute
                  path="/template_add"
                  name="template"
                  component={TemplateAdd}
                />
                <ProtectedRoute
                  path="/template_design"
                  name="template"
                  component={TemplateAdd1}
                />
                <ProtectedRoute
                  path="/template_history_list"
                  component={TemplateHistoryList}
                />
                <ProtectedRoute
                  path="/template_request_list"
                  component={TemplateRequestList}
                />
                <ProtectedRoute path="/design_template" component={DragNDrop} />
                <ProtectedRoute
                  path="/select_option"
                  component={SelectOption}
                />
                <ProtectedRoute
                  path="/print_data_add"
                  component={GenerateRandomCode}
                />
                <ProtectedRoute
                  path="/print_data_list"
                  component={RendomCodeGenerationList}
                />
                <ProtectedRoute
                  path="/printing_job_add"
                  component={PrintingJobAdd}
                />
                <ProtectedRoute
                  path="/printing_job_list"
                  component={PrintingJobList}
                />
                <ProtectedRoute
                  path="/template_histories"
                  component={GenerateToken}
                />
                <ProtectedRoute
                  path="/label_production_add"
                  component={RollAdd}
                />
                <ProtectedRoute
                  path="/label_production_list"
                  component={RollList}
                />
                <ProtectedRoute
                  path="/label_production_edit"
                  component={RollEdit}
                />
                <ProtectedRoute
                  path="/roll_file_list"
                  component={RollFileList}
                />
                <ProtectedRoute path="/plant_add" component={PlantAdd} />
                <ProtectedRoute path="/plant_list" component={PlantList} />
                <ProtectedRoute path="/plant_edit" component={PlantEdit} />
                <ProtectedRoute path="/vendor_add" component={VendorAdd} />
                <ProtectedRoute path="/vendor_list" component={VendorList} />
                <ProtectedRoute path="/vendor_edit" component={VendorEdit} />
                <ProtectedRoute path="/product_add" component={ProductAdd} />
                <ProtectedRoute path="/product_list" component={ProductList} />
                <ProtectedRoute path="/product_edit" component={ProductEdit} />
                <ProtectedRoute path="/packaging_list" component={PackagingList} />
                <ProtectedRoute path="/packaging_add" component={PackagingAdd} />
                <ProtectedRoute path="/packaging_edit" component={PackagingEdit} />
                <ProtectedRoute path="/link_qr" component={LinkQrWithProduct} />
                <ProtectedRoute
                  path="/user_privladge"
                  component={UserPriviladge}
                />
                <ProtectedRoute path="/view" component={View} />
                <ProtectedRoute
                  path="/token_data_add"
                  component={ApUtilityAdd}
                />
                <ProtectedRoute
                  path="/token_data_list"
                  component={ApUtilityList}
                />
                <ProtectedRoute
                  path="/token_data_report"
                  component={TokenDataReport}
                />
                <ProtectedRoute
                  path="/master_product_add"
                  component={MatntAdd}
                />
                <ProtectedRoute
                  path="/master_product_edit"
                  component={MatnrEdit}
                />
                <ProtectedRoute
                  path="/master_product_list"
                  component={MatnrList}
                />
                <ProtectedRoute path="/matnr_add" component={MatntAdd} />
                <ProtectedRoute path="/matnr_edit" component={MatnrEdit} />
                <ProtectedRoute path="/matnr_list" component={MatnrList} />
                <ProtectedRoute
                  path="/guilloche_images_add"
                  component={GuillocheImageAdd}
                />
                <ProtectedRoute
                  path="/guilloche_images_edit"
                  component={GuillocheImageEdit}
                />
                <ProtectedRoute
                  path="/guilloche_images_list"
                  component={GuillocheImageList}
                />
                <ProtectedRoute
                  path="/invalid_scan_list"
                  component={InvalidScanList}
                />
                <ProtectedRoute path="/report" component={Report} />
                <ProtectedRoute path="/reports" component={Reports} />
                <ProtectedRoute path="/enduser_reward_reports" component={EndUserRewardReport} />
                <ProtectedRoute
                  path="/inventory_list"
                  component={InventoryList}
                />
                <ProtectedRoute
                  path="/inventory_report"
                  component={InventoryReport}
                />
                <ProtectedRoute
                  path="/consignment_list"
                  component={ConsignmentList}
                />
                <ProtectedRoute
                  path="/consignment_fcode_list"
                  component={ConsignmentCodeList}
                />
                <ProtectedRoute path="/search_qr_code" component={SearchQrCodeList} />
                <ProtectedRoute path="/search" component={SearchQrCode} />

                <ProtectedRoute path="/download/invalid-scan-report" component={InvalidScanReport} />

                <ProtectedRoute path={"/smart-bin"} component={SmartBinList} />

                <LoadingIndicator />
                <AppFooter />
              </div>
            </div>

            <AppConfig
              layoutMode={this.state.layoutMode}
              darkMenu={this.state.darkMenu}
              profileMode={this.state.profileMode}
              themeColor={this.state.themeColor}
              configDialogActive={this.state.configDialogActive}
              changeMenuMode={this.changeMenuMode}
              changeMenuColor={this.changeMenuColor}
              changeProfileMode={this.changeProfileMode}
              changeTheme={this.changeTheme}
              onConfigButtonClick={this.onConfigButtonClick}
              onConfigCloseClick={this.onConfigCloseClick}
              onConfigClick={this.onConfigClick}
            />

            <AppRightPanel
              expanded={this.state.rightPanelActive}
              onContentClick={this.onRightPanelClick}
            />

            <div className="layout-mask"></div>
          </div>
        </div>
      </PriviladgeContext.Provider>
    );
  }
}

export default App;

export function isTokenValid(token) {
  let decodedToken = jwt.decode(token);
  if (token && decodedToken) {
    const expiry = decodedToken.exp;

    const now = new Date();
    let notTime = now.getTime();
    let expireTime = expiry * 1000;
    return expireTime > notTime;
  }
  return false;
}
