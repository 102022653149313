import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Growl } from 'primereact/growl';
import React, { Component } from 'react';
import { trackPromise } from 'react-promise-tracker';
import { HttpService } from '../../service/HttpService';
import { checkForError } from '../../utility/AppUtility';
import constant from './../../constant/Constant';
import {PriviladgeContext} from './../../context/priviladge-context'
import  CheckPriviladge from './../../hoc/CheckPriviladge'
import { AppBreadcrumb } from '../../AppBreadcrumb';

export class ApUtilityList extends Component {

    constructor() {
        super();
        this.state = {
            dataTableValue: [],
            page: 1,
            totalRecords: 0,
            size: 100
        };

        this.httpService = new HttpService();


    }


    componentDidMount() {

        this.getList(this.state.page - 1);
    }
    getList = (page) => {
        trackPromise(
            this.httpService.getApi('ap-utilities').then((response) => {
                this.setState({ dataTableValue: response.data, totalRecords: response.data.totalElements });
                console.log(response.data);
            }).catch((error) => {
                if (checkForError(error))
                    this.showMessage('error', 'Error Message', error.response.data.title)
            }));
    }
    showMessage(severity, summary, message) {
        let msg = { severity: severity, summary: summary, detail: message };
        this.growl.show(msg);
    }
    onPageChange = (event) => {
        this.setState({ page: event.page })
        this.getList(event.page)

    }

    download = (rowData, ext) => {
        var a = document.createElement('a');
        a.href = constant.TEMPLATE_URl + rowData.fileName;
        a.download = rowData.fileName
        a.click();
        a.remove();
    }
    sendForPrinting = (rowData) => {
        trackPromise(
            this.httpService.getApi('printingJob/storeftp/' + rowData.id).then((response) => {

            }).catch((error) => {
                if (checkForError(error))
                    this.showMessage('error', 'Error Message', error.response.data.title)
            }));
    }


    add = () => {
        this.props.history.push('token_data_add')
    }
    actionTemplate = (rowData, column) => {
        return <div style={{ width: "110px" }}>
            {rowData.fileStatus === 'COMPLETED' &&

                <Button type="button" tooltip="Csv" tooltipOptions={{ position: 'bottom' }} icon="pi-md-file-download" className="p-button-info" onClick={() => this.download(rowData,)} />
            }

        </div>;
    }
    render() {
        let actionHeader = <Button type="button" icon="pi-md-settings" />;

        return<> 
                    <AppBreadcrumb breadcrumdItems={[{ label: 'Token Data'}]} />

        <div className="p-grid">
            <div className="p-col-12">
                <Growl ref={(el) => this.growl = el} style={{ marginTop: '75px' }} />

                <div className="card card-w-title datatable-demo">
                    <div className="p-col-12">
                        <font className="font21Weight500" >Token Data List</font>
                        <CheckPriviladge priviladge={{name: "Token Data", priority: 2}}>
                        <Button className="floatRight" icon="pi-md-plus"
                            tooltip="Add Token Data" label="Add" tooltipOptions={{ position: 'left' }} onClick={() => this.add()} />
</CheckPriviladge>

                    </div>
                    <div className="p-col-12">
                    </div>
                    <DataTable ref={(el) => this.dt = el} value={this.state.dataTableValue} header="List of Token Data"
                        paginator={true} rows={10}
                        responsive={true} resizableColumns={true} columnResizeMode="fit"
                    >
                        <Column field="fileName" header="File" sortable={true} filter={true} filterPlaceholder="Contains" />
                        <Column field="poNumber" header="PO Number" sortable={true} filter={true} filterPlaceholder="Contains" />
                        <Column field="matnr.tokenType" header="Token Type" sortable={true} filter={true} filterPlaceholder="Contains" />
                        <Column field="matnr.packSize" header="Pack Size" sortable={true} filter={true} filterPlaceholder="Contains" />
                        <Column field="created_by" header="Created by" sortable={true} filter={true} filterPlaceholder="Contains" />
                        <Column field="created_on" header="Date" sortable={true} filter={true} filterPlaceholder="Contains" />
                        <Column field="fileStatus" header="Status" sortable={true} filter={true} filterPlaceholder="Contains" />

                        <Column header={actionHeader} body={this.actionTemplate} style={{ textAlign: 'center', width: '9em' }} />


                    </DataTable>

                </div>
            </div>
        </div></>
    }
}